import { useEffect, useState } from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'
import { useParams} from 'react-router-dom';

function News() {
    const { id } = useParams();
    const [document] = usePrismicDocumentByUID('news', id);

    return (
        <div className="contents_wrap">
            <div className="news_detail">
                <div className='detail_title'>
                {document&&document.data.title[0].text}
                </div>
                <div className="detail_contents">
                <div className='thumbnail_img'><img src={document&&document.data.thumnail.url} alt="" /></div>
                {document&&document.data.contents.map((con,cIndex)=>{ 
                    return (<div key={cIndex}>{con.text===''?(<br/>):con.text}</div>)
                    
                    })}
                </div>
                <div className='bt_section'>
                <a target={'_blank'} rel="noreferrer" href={document&&document.data.original_link.url}><button className='default_bt submit_bt'><span className="t_kr">기사원문보기</span><span className="t_en">View Original Article</span></button></a>
                </div>
                
                <div className="more_bt_section">
                    <a href="/news"><button className="default_bt more_bt">Back to List</button></a>
                </div>
            </div>
        </div>

    )



}


export default News;
