import { useEffect, useState } from 'react';


import { useLocation } from 'react-router-dom';
function Ethics() {
    const mobileYn = window.innerWidth>900?false:true;


    return (
        <div className="contents_wrap">
            <div className="policy">
            <div className='ani_t_line'>
                <div className='section_title'>
                    <span className="t_kr">윤리강령</span><span className='t_en'>ETHICAL MANAGEMENT</span>
                </div>
            </div>
                
                <div className='poli_section'>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_contents'>
								이 지침은 기업의 종사자로서 당연히 지켜야 할 최소한의 윤리 기준과 행동 양식을 제시함으로써 올바른 가치관의 정립과 바람직한 근무자세 및 업무처리의 원칙을 확립하고자 하는데 그 목적이 있으며, 따라서 이 지침에서 정하지 않은 사항에 대하여는 일반적인 기업인으로서의 양식을 기준으로 공평과 신의 성실의 원칙에 따라 판단하고 행동한다.<br /><br />
또한, 이 지침의 이행 여부 확인 및 지도는 경영관리본부가 담당하며, 윤리 문제 발생 시 경영관리본부장이 인사위원회에 즉시 회부하여 조치한다.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='poli_section'>
                    <div className='poli_title'>제1장 고객에 대한 책임</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제1조{mobileYn?' ':<br />}(고객감동 실천)</div>
                            <div className='poli_contents'>
								① 우리는 가장 신속하고 정확하며 항상 친절한 최상의 서비스를 고객에게 제공한다.<br /><br />
								② 고객의 욕구에 부응한 상품 개발과 지속적인 서비스 혁신으로 고객의 편리와 이익을 최우선으로 삼는다.<br /><br />
								③ 항상 용모와 복장을 단정히 하여 공손하고 미소 띤 언행으로 고객을 응대한다.<br /><br />
								④ 고객과의 약속을 생명처럼 소중히 여기고 지킨다.<br /><br />
								⑤ 고객이 최선의 서비스를 선택할 수 있도록 거짓 없는 정보를 제공한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제2조{mobileYn?' ':<br />}(영업활동의 기본원칙)</div>
                            <div className='poli_contents'>
								① 우리는 영업활동에 수반되는 고객과의 관계에 있어 관계 법률을 준수함은 물론 정직, 성실, 청렴의 모범을 보여야 한다.<br /><br />
								② 고객, 경쟁자, 납품업자와 그들의 상품 또는 용역에 관하여 허위 또는 착오를 유발할 수 있는 언행을 하지 않으며, 금품수수 또는 향응을 제공받지 아니한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제3조{mobileYn?' ':<br />}(고객정보 관리)</div>
                            <div className='poli_contents'>
								① 고객에 대한 정보가 수록된 모든 자료와 기록 파일은 유형자산과 동등하게 존중하고 안전하게 보관 관리한다.<br /><br />
								② 고객 관련 정보는 법률에서 따로 정한 경우 이외는 본인의 사전승인 없이 제3자에게 공개하지 않는다.
							</div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제4조{mobileYn?' ':<br />}(쾌적한 환경 조성)</div>
                            <div className='poli_contents'>
								정리, 정돈을 생활화하여 사무실 내부와 주변을 항상 쾌적하고 청결하게 유지한다.
							</div>
                        </div>
                    </div>
                </div>
                <div className='poli_section'>
                    <div className='poli_title'>제2장 사회 경제에 대한 책임</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제5조{mobileYn?' ':<br />}(사회 경제에 대한 사명)</div>
                            <div className='poli_contents'>
								① 우리는 IT 산업의 중추적 기업으로서 산업 활성화를 선도하여 국민경제의 균형발전에 이바지하고 사회 발전 요구에 부응할 사명을 띠고 있다. 
							</div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제6조{mobileYn?' ':<br />}(사회 경제에 대한 역할)</div>
                            <div className='poli_contents'>
								① 기술개발 능력을 제고하여 IT 산업을 활성화시킴으로써 지역경제 발전과 고용창출에 기여한다.<br /><br />
								② 사회 경제의 일원으로서 수익의 지역사회 환원 및 유관기관 협력, 학술회 지원 등 사회 경제 활동에 적극 참여한다.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='poli_section'>
                    <div className='poli_title'>제3장 주주에 대한 책임</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제7조{mobileYn?' ':<br />}(주주이익 보호)</div>
                            <div className='poli_contents'>
								① 기업가치의 극대화 및 적극적 홍보, IR 등을 통해 자사주식이 자본시장에서 합당한 평가를 받도록 노력한다. <br /><br />
								② 주주가 그 권리를 침해 당했을 경우에는 즉시 이를 사과하고 재발방지를 약속한다.<br /><br />
								③ 소액주주 및 외국주주를 포함한 모든 주주에게 평등한 대우를 보장한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제8조{mobileYn?' ':<br />}(성실한 정보 제공)</div>
                            <div className='poli_contents'>
								① 기업의 현황과 전망에 대한 신뢰성 있고 유용한 정보를 적극 제공하여 투자판단에 도움을 주며, 주요 의사결정에 대한 참여기회를 개방한다.<br /><br />
								② 주주에게 정보를 전달하는 경로는 주주가 가장 신속하고 손쉽게 이용할 수 있는 매체를 선택하고 공시자료를 상시 비치하여 열람할 수 있도록 한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제9조{mobileYn?' ':<br />}(엄격한 회계 관리)</div>
                            <div className='poli_contents'>
								① 일반 회계원칙에 따라 투명하고 정확한 회계기록, 작성에 만전을 기한다.<br /><br />
								② 장부상의 기록은 정기적으로 점검, 확인하고 기장 내용과 실자산의 차액 또는 가공, 분식기록 발견 시 적법한 절차를 통해 그 원인을 소명한 후 공개적으로 조정한다.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='poli_section'>
                    <div className='poli_title'>제4장 직장에 대한 책임</div>
                    <div className='poli_items'>
                    	<div className='poli_subtitle'>제1절 사용자로서의 책임</div>
                        <div className='poli_item'>
                            <div className='poli_title'>제10조{mobileYn?' ':<br />}(공정한 인사)</div>
                            <div className='poli_contents'>
								고용 및 승진에 있어 성별, 학력, 나이, 종교, 출신지역 등에 따라 차별하지 않고 공정한 기회를 제공하고 개인의 능력과 성과에 대한 공정한 평가와 보상을 통하여 업무 성취 동기를 유발한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제11조{mobileYn?' ':<br />}(삶의 질 향상)</div>
                            <div className='poli_contents'>
								① 직원 개개인의 존엄성과 개성은 다른 직원에 의하여 존중되어야 함을 인정한다.<br /><br />
								② 직원의 인격과 자질을 향상시키고 업무 수행상 필요한 지식과 기능을 습득케 하여 기업발전에 기여하도록 모든 직원에게 교육의 기회를 공평하게 제공한다.<br /><br />
								③ 직원 본인과 가족의 건강, 교육, 휴식, 노후생활, 문화행사 등 삶의 질 향상을 위한 프로그램 개발에 노력한다.<br /><br />
								④ 임직원이 쾌적하고 안전하게 일할 수 있는 근무환경을 조성한다.
							</div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제12조{mobileYn?' ':<br />} (자율과 창의 존중)</div>
                            <div className='poli_contents'>
								① 업무의 결과뿐만 아니라 과정도 소중히 여기며 기본과 원칙을 지키고, 최선을 다한 실수는 애정과 격려로써 대한다.<br /><br />
								② 업무처리 및 인간관계에 있어서 모든 권위주의적 요소를 일소하여 직원 모두가 소신을 갖고 자율적으로 업무를 추진할 수 있는 근무여건을 조성한다.<br /><br />
								③ 원활한 의사소통 채널을 확보하여 기업 내의 갈등을 사전에 예방하고 직원들이 창의력을 발휘하여 정당한 방법으로 경영에 참여할 수 있도록 한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제13조{mobileYn?' ':<br />}(노사화합)</div>
                            <div className='poli_contents'>
								신뢰와 화합의 바탕 위에 공존 공영의 미래지향적인 노사관계를 구축한다.
                            </div>
                        </div>
                    	<div className='poli_subtitle'>제2절 직원의 책임과 기본자세</div>
                        <div className='poli_item'>
                            <div className='poli_title'>제14조{mobileYn?' ':<br />}(직장관)</div>
                            <div className='poli_contents'>
								기업은 자아실현의 도장이며 터전으로써 제 2의 가정이라는 인식으로 나의 발전이 기업의 발전이며, 기업 발전이 곧 나의 발전이라는 공동운명체적 천직의식을 가지고 기업에 대한 자부심과 애정을 가져야 한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제15조{mobileYn?' ':<br />}(직장인의 기본자세)</div>
                            <div className='poli_contents'>
								① 직원 개개인이 기업을 대표한다는 마음가짐으로 책임감과 주인의식을 가지고 업무에 임한다.<br /><br />
								② 고객이 있어야 기업이 있고 내가 있다는 고객 본위의 상인정신으로 고객에게 기쁨과 만족을 줄 수 있도록 최고 수준의 서비스를 제공한다.<br /><br />
								③ 항상 문제의식과 개선의식을 가지고 자기 연마에 힘써 업무의 질을 향상시킨다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제16조{mobileYn?' ':<br />}(복무원칙)</div>
                            <div className='poli_contents'>
								① 모든 직원은 법령, 정관, 제 규정을 준수하고 기업의 이익고 명예 및 업적 신장을 위하여 최선을 다하여야 한다.<br /><br />
								② 직원은 검소와 절약을 바탕으로 모든 직장인의 수범이 되어야 하며, 과다한 부채, 부동산 투기, 주식과다투자, 불건전오락행위 또는 사적금전대차, 금품수수, 향응을 받지 않는다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제17조{mobileYn?' ':<br />}(공사 분별)</div>
                            <div className='poli_contents'>
								임직원은 근무시간 중 업무에 최선을 다하고, 기업의 재산을 사적용도로 사용하는 행위가 없도록 하여야 한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제18조{mobileYn?' ':<br />}(직원간 상호존중)</div>
                            <div className='poli_contents'>
								① 상하, 동료간 더불어 협조하며 나의 일을 존중하는 만큼 남의 일도 존중하고 협력한다.<br /><br />
								② 직원간 중상모략 및 상호비방 또는 동료 상하간의 결속력을 해치는 표현이나 언행을 하지 않는다.<br /><br />
								③ 남, 여 직원간 인격을 존중하고 예의를 지키며 수치심을 유발시키는 성적 유혹이나 농담, 신체적 접촉 행위를 비롯하여 근무환경을 저해하는 일체의 성희롱 행위를 하지 않는다. 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='poli_section'>
                    <div className='poli_title'>제5장 가정에 대한 책임</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제19조{mobileYn?' ':<br />}(건전한 가정관) </div>
                            <div className='poli_contents'>
								우리는 사회의 기본 단위인 한 가정의 가장으로서, 자녀로서, 남편 또는 아내로서 건전하고 행복한 가정을 가꾸고 가족과 함게 삶의 질을 향상시키도록 항상 노력하여야 한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제20조{mobileYn?' ':<br />}(건강한 가정)</div>
                            <div className='poli_contents'>
								① 아침운동, 아침식사 등 온 가족이 하루의 시작인 아침을 함께 하도록 노력한다.<br /><br />
								② 가족 구성원 각자 1인 1운동 갖기를 실시하여 스포츠를 생활화 하도록 노력한다.<br /><br />
								③ 하루 5분 이상 가족간 대화의 시간을 갖도록 노력한다.
							</div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제21조{mobileYn?' ':<br />}(존경과 애정)</div>
                            <div className='poli_contents'>
								① 아침 기상 또는 출타, 귀가시 가족간 상호 인사말을 생활화 한다.<br /><br />
								② 부모에겐 효와 존경심을 부부와 자녀에겐 애정과 존중의 언행으로 예의 가정을 이룬다.<br /><br />
								③ 가족간 대화는 부드럽고 정겹게 나누고 항상 맑고 밝은 표정을 유지한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제22조{mobileYn?' ':<br />}(문화와 봉사활동)</div>
                            <div className='poli_contents'>
								① 월 1회 이상 가족과 함께 문화 행사에 참여토록 노력한다.<br /><br />
								② 부부간 공동 취미를 만들어 부부가 함께 하는 시간을 갖도록 노력한다.<br /><br />
								③ 전 가족 월 1권 이상 독서하는 여건을 조성하도록 한다.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='poli_section'>
                    <div className='poli_title'>제6장 경쟁사에 대한 책임</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제23조{mobileYn?' ':<br />}(공정한 경쟁)</div>
                            <div className='poli_contents'>
								우리는 경쟁기업의 영업권을 존중하고, 타기업을 비방하거나 신상품 개발 등 중요 정보를 부정하게 입수, 이용함 없이 정당한 방법과 실력으로 경쟁한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제24조{mobileYn?' ':<br />}(담합금지)</div>
                            <div className='poli_contents'>
								우리는 다른 기업과의 담합을 단호히 배격하고 시장원리에 부함하는 경쟁을 통해 고객의 다양한 선택권을 보장한다.
							</div>
                        </div>
					</div>
				</div>
                <div className='poli_section'>
                    <div className='poli_title'>제7장 사회와 국가에 대한 책임</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제25조{mobileYn?' ':<br />}(법규준수 및 사회발전 기여)</div>
                            <div className='poli_contents'>
								① 기업은 제 법과 질서를 준수하고 사회적 가치관을 존중하며 지역사회와 국가의 이익에 부응하는 방향으로 업무를 수행한다.<br /><br />
								② 사회를 정신적으로 풍요롭게 하는 학문과 예술, 문화, 체육 활동에 적극 참여, 지원한다.<br /><br />
								③ 고용 창출과 성실한 납세로 국가 발전에 기여한다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제26조{mobileYn?' ':<br />}(정치활동 및 헌금)</div>
                            <div className='poli_contents'>
								① 우리는 정치적 중립을 해치는 일체의 언행을 삼간다.<br /><br />
								② 개인 또는 단체에 불법적인 헌금, 선물, 향응, 편의를 제공함으로써 부당한 경쟁적 우위를 추구하지 않는다.
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제27조{mobileYn?' ':<br />}(환경 보호)</div>
                            <div className='poli_contents'>
								① 환경문제에 대한 중요성을 깊이 인식하여 정부, 기업, 시민단체와의 유기적 관계를 긴밀히 유지하고 상호 협력을 아끼지 않는다.<br /><br />
								② 우리 기업은 물론 거래 기업체들이 자원의 활용, 오염원의 원천제거 등 환경보호를 위한 기술 개발 및 투자에 노력할 수 있도록 적극 지원한다.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='poli_section'>
                    <div className='poli_title'>제8장 윤리적 자가 진단</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_title'>제28조{mobileYn?' ':<br />}(윤리성 평가) </div>
                            <div className='poli_contents'>
								우리는 주변에서 윤리적 가치관에 배치되는 다음과 같은 분위기가 조성되고 있지 않는지 상시 자가평가를 하여 개선하도록 노력한다.
                                <div className='sub_info'>
									- 다른 사람도 그렇게 하는데 뭘?<br />
									- 어떻게 해서든 이 일을 해내!<br />
									- 이번 한 번만!<br />
									- 세상은 두리둥실 사는거야!<br />
									- 아무도 손해보는 사람 없잖아?<br />
									- 아무도 모르겠지?<br />
									- 적당히!
								</div>
                            </div>
                        </div>
                        <div className='poli_item'>
                            <div className='poli_title'>제29조{mobileYn?' ':<br />}(담합금지)</div>
                            <div className='poli_contents'>
								업무 처리 중 어떤 결정이 옳은 것인지 고민할 때가 있다면 다음과 같은 기준으로 판단한다.
                                <div className='sub_info'>
									- 기업의 윤리강령의 가치에 합당한가?<br />
									- 이 일로 인해 억울한 피해자가 생기지 않을까?<br />
									- 이 일을 다시 맡아도 똑같이 할까?<br />
									- 이렇게 하고 나면 두 다리 뻗고 잘 수 있을까?<br />
									- 가족에게 이 일을 말해도 될 것인가?<br />
									- 이 일이 언론에 보도되어도 괜찮은가?
								</div>
							</div>
                        </div>
					</div>
				</div>
                <div className='poli_section'>
                    <div className='poli_title'>제9장 이행과 확인</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_contents'>
								모든 직원은 이 윤리 행동 지침의 내용을 숙지하고 이행 할 의무가 있으며 윤리강령의 내용과 정신을 항상 존중되어야 한다. 대표이사는 이 윤리 행동 지침이 항구적으로 실천되도록 적극 노력하며 준법감시인은 그 이행 결과를 계속 확인 검토할 것이다.<br /><br />
								이 윤리 행동 지침의 시행이 준법의식을 함양하고 기업의 기업문화와 사회적 책임에 대한 재조명의 계기가 될 것으로 확신하며 지침에서 정한 모든 사항이 준수될 때 기업은 스스로 일할 맛과 꿈이 있는 보람의 일터로써 사회와 고객의 사랑 속에서 성장과 발전을 지속할 것이다.
                            </div>
                        </div>
					</div>
				</div>
                <div className='poli_section'>
                    <div className='poli_title'>제10장 재무보고</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_contents'>
								우리는 모든 공식적인 업무관련 기록, 회계 및 재정 관련 기록, 품질관련 기록을 관련법령과 사내규정에 따라 정확하게 기록하고 보존연한동안 이를 관리합니다.<br /><br />
								우리는 회사에서 발생하는 사건 및 사고를 보고체계에 따라 정확하고 신속하게 보고해야 하며 회사차원의 대응이 필요한 주무부서에 정확한 사실을 알려야 합니다.
                            </div>
                        </div>
					</div>
				</div>
                <div className='poli_section'>
                    <div className='poli_title'>부 칙</div>
                    <div className='poli_items'>
                        <div className='poli_item'>
                            <div className='poli_contents'>
								1. 이 규정은 2017 년 06월 01일부터 시행한다.<br /><br />
							    2. 이 규정은 2022년 01월 01일부터 시행한다
                            </div>
                        </div>
					</div>
				</div>

            </div>
        </div >

    )



}


export default Ethics  ;
