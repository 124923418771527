import { useEffect, useState } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react'
import {getCookie} from '../lib/common'
import { useLocation } from 'react-router-dom';
function Announcement() {
    const [announcement] = useAllPrismicDocumentsByType('announcement');
    const [searchKey,setSearchKey]= useState('');
    const [enMode,setEnMode]= useState(false); 
    const location = useLocation();
    useEffect(()=>{
        const lan = getCookie('lan');
       
        if(lan==='en'){
            setEnMode(true);
        }
    },[location]);
    useEffect(()=>{
      
    },[announcement])
    return (
        <div className="contents_wrap">
            <div className="announcement">
                <div className='ani_t_line'>
                    <div className="section_title">
                        <span className="t_kr">공고정보</span><span className='t_en'>ANNOUNCEMENT</span>
                    </div>
                </div>

                <div className="search_section">
                    <input type={'text'} onChange={(e)=>{setSearchKey(e.target.value)}} placeholder={enMode?'search':'검색어를 입력하세요'} style={{ backgroundImage: 'url(/img/ic_search_g.svg)' }} />
                </div>
                <div className='announcement_items'>
                    {announcement&&announcement.sort((function(a, b) { 

return new Date(b.data.writen_date) - new Date(a.data.writen_date);
})).filter((an)=>an.data.title[0].text.indexOf(searchKey) !== -1).map((an,anIndex)=>(<a key={anIndex} href={"/announcement/"+an.uid}>
                        <div className='an_item'>
                            <div className='an_date'>{an.data.writen_date}</div>
                            <div className='an_type'>{an.data.type===null?'IR':an.data.type}</div>
                            <div className='an_title'>{an.data.title[0].text}</div>
                          
                        </div>
                    </a>))}
                  
                </div>
                {announcement&&announcement.filter((an)=>an.data.title[0].text.indexOf(searchKey) !== -1).length===0&&<div className='no_contents'>
                <span className='t_kr'>검색된 공고가 없습니다.</span><span className='t_en'>No announcements have been searched.</span>
                </div>}


            </div>
        </div>

    )



}


export default Announcement;
