import './style/common.scss';
import './style/mobile.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from "./component/Header";
import Footer from "./component/Footer";
import Main from "./page/Main";
import Artist from "./page/Artist";
import News from "./page/News";
import NewsDetail from "./page/NewsDetail"
import Announcement from "./page/Announcement"
import AnnouncementDetail from './page/AnnouncementDetail';
import About from "./page/About";
import What from "./page/What";
import Contact from "./page/Contact";
import Audition from "./page/Audition";
import Policy from "./page/Policy";
import Ethics from "./page/Ethics";
import Dart from './page/Dart';

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
      <Route exact path="/" element={<Main/>}/>
      <Route exact path="/about" element={<About/>}/>
      <Route exact path="/what" element={<What/>}/>
      <Route exact path="/what/:id" element={<What/>}/>
      <Route exact path="/artist" element={<Artist/>}/>
      <Route exact path="/news" element={<News/>}/>
      <Route exact path="/news/:id" element={<NewsDetail/>}/>
      <Route exact path="/announcement" element={<Announcement/>}/>
      <Route exact path="/announcement/:id" element={<AnnouncementDetail/>}/>
      <Route exact path="/audition" element={<Audition/>}/>
      <Route exact path="/dart" element={<Dart/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      <Route exact path="/policy" element={<Policy/>}/>
      <Route exact path="/ethics" element={<Ethics/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
