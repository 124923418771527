import { useEffect, useState,useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import { getCookie, setCookie } from '../lib/common'




function Header() {
    const [menuActive, setMenuActive] = useState(false);
    const [enMode, setEnMode] = useState(getCookie('lan')==='en'?true:false); 
    const location = useLocation();
    const [whiteMode,setWhiteMode] = useState(false);
    const  lastScroll = useRef(0);
    const allAnimations = gsap.exportRoot();

  
    const scrollHeaderSet = ()=>{
       
        const st = $(document).scrollTop();
       
            if(st>0&&(st > lastScroll.current||st>$(document).height()-window.innerHeight-30)){ 
                $('.header').addClass('scroll_up')
                $('.cate_menu').addClass('scroll_up')
              
            } else{ 
                $('.header').removeClass('scroll_up')
                $('.cate_menu').removeClass('scroll_up')
               
            } 

            if(location.pathname==="/"&&st<window.innerHeight){
                setWhiteMode(true)
            }else if(location.pathname==="/"){ 
                setWhiteMode(false);
            }
            lastScroll.current = st;
    }
    useEffect(() => {
        lastScroll.current = 0;
        //const lan = ;

        if (enMode) {
            //setEnMode(true);
            $('.contents_wrap').addClass('en_languege')
        } else {
            $('.contents_wrap').removeClass('en_languege')
        }

        if(location.pathname==="/") {setWhiteMode(true);}
        else{ 
             setWhiteMode(false);
            }

        

        window.scrollTo(0, 0);
        setMenuActive(false);
        window.scrollTo(0, 0);

       
        $(document).on('scroll',scrollHeaderSet);



        const Images = [...document.querySelectorAll('.ani_img img')];
        const ani_text_items = [...document.querySelectorAll('.ani_t')];
        const ani_section_items = [...document.querySelectorAll('.ani_section')];


       
        gsap.from(".ani_t_line > div", 1, { y: "15vw" });
        Images.forEach(img => {

            allAnimations.from(img, 1, {
                opacity: 0,
                scrollTrigger: {
                    trigger: img,
                    start: "top +=800", // when the top of the trigger hits the top of the viewport

                }
            })

        });
      
        ani_text_items.forEach(t => {

            allAnimations.from(t, 1, {
                opacity: 0,
                y: 100,
                scrollTrigger: {
                    trigger: t,
                    start: "top +=" + window.innerHeight * 0.8, // when the top of the trigger hits the top of the viewport

                }
            })
        });
        ani_section_items.forEach(section => {

            allAnimations.from(section, 1, {
                opacity: 0,
                y: 100,
                scrollTrigger: {
                    trigger: section,
                    start: "top +=" + window.innerHeight * 0.8, // when the top of the trigger hits the top of the viewport

                }
            })
        });

        return ()=>{
            
            let Alltrigger = ScrollTrigger.getAll();
            console.log(Alltrigger);
            if (allAnimations) {
                var animations = allAnimations.getChildren(),
                    startTime = allAnimations.startTime(),
                    root = allAnimations.timeline,
                    l = animations.length,
                    i;
                for (i = 0; i < l; i++) {
                    animations[i].kill();
                }
                allAnimations.kill();
            }
           
        for (let i = 0; i < Alltrigger.length; i++) {
            Alltrigger[i].kill();
         }
            $(document).off('scroll',scrollHeaderSet);
        }




    }, [location, window.innerWidth]);


    const languegeChange = (lan) => {
        setCookie("lan", lan);
        window.scrollTo(0, 0);
        let Alltrigger = ScrollTrigger.getAll();
        if (allAnimations) {
            var animations = allAnimations.getChildren(),
                startTime = allAnimations.startTime(),
                root = allAnimations.timeline,
                l = animations.length,
                i;
            for (i = 0; i < l; i++) {
                animations[i].kill();
            }
            animations = allAnimations.getChildren();
            console.log(animations);
            allAnimations.kill();
        }
        for (let i = 0; i < Alltrigger.length; i++) {
        Alltrigger[i].kill();
        }
         Alltrigger = ScrollTrigger.getAll();
        
        //window.location.href = window.location.href;
        window.location.reload();

    }
    return (
        <header className={"header "+ (menuActive ? 'menu_active' : '')}>

            <div className="logo_section"><a href="/"><img src={"/img/logo" + ((menuActive||whiteMode)? '_w' : '') + ".svg"} alt="" /></a></div>
            <div className={"menu_right_section" + (menuActive? ' active' : '')+ (whiteMode? ' white' : '')}>
                <div className="mn_language" onClick={() => { languegeChange(enMode ? 'kr' : 'en') }}>{enMode ? 'KOR' : 'ENG'}</div>
                <div className="mn_ic" onClick={() => { setMenuActive(!menuActive) }}>
                    <div className="mn_bar"></div>
                    <div className="mn_bar"></div>
                    <div className="mn_bar"></div>
                </div>
            </div>
            <div className={"menu_pop " + (menuActive ? 'active' : '')}>
                <ul className="mnp_menu">
                    <li><Link to="/about">ABOUT US</Link><img src="/img/ic_arrow_right_w.svg" alt="" /></li>
                    <li><Link to="/what">WHAT WE DO</Link><img src="/img/ic_arrow_right_w.svg" alt="" /></li>
                    <li><Link to="/artist">ARTIST</Link><img src="/img/ic_arrow_right_w.svg" alt="" /></li>
                    <li><Link to="/audition">AUDITION</Link><img src="/img/ic_arrow_right_w.svg" alt="" /></li>
                </ul>
                <ul className='mnp_submenu'>
                    <li><Link to="/contact">CONTACT</Link></li>
                    <li><Link to="/announcement">IR</Link></li>
                    {!enMode&&<li><Link to="/dart">DART 공시정보</Link></li>}
                </ul>
                <ul className="sns_section">
                    <li><a href="https://www.facebook.com/IOKCOMPANY" rel="noreferrer" target="_blank"><img src="/img/ic_facebook_w.svg" alt="" /></a></li>
                    <li><a href="https://www.instagram.com/iokcompany/" rel="noreferrer" target="_blank"><img src="/img/ic_instagram_w.svg" alt="" /></a></li>
                    <li><a href="https://post.naver.com/my.naver?memberNo=37878409" rel="noreferrer" target="_blank"><img width="20" src="/img/ic_blog_w.svg" alt=""  /></a></li>
                    <li><a href="https://www.youtube.com/channel/UCeybOdFGMm_RxjXA7iS2jBw" rel="noreferrer" target="_blank"><img width="20" src="/img/ic_youtube_w.svg" alt="" /></a></li>
                </ul>
            </div>

        </header>

    )



}


export default Header;
