import { useEffect, useState } from 'react';
import { usePrismicDocumentByUID } from '@prismicio/react'

import { useParams} from 'react-router-dom';
function AnnouncementDetail() {

   
    const { id } = useParams();
    const [document] = usePrismicDocumentByUID('announcement', id);
    useEffect(()=>{
        console.log(document);
    },[document])
    return (
        <div className="contents_wrap">
            <div className="announcement_detail">
                <div className='detail_title'>
                {document&&document.data.title[0].text}
                </div>
                <div className="detail_contents">
                {document&&document.data.contents.map((con,cIndex)=>{ 
                    return (
                    <div key={cIndex}>
                        {con.type==='image'&&<div className='contents_img'><img src={con.url} alt="" /></div>}
                        {con.type!=='image'&&(con.text===''?(<br/>):con.text)}
                        </div>
                    
                    )
                    
                    })}
                {document&&document.data.files.url&&<div className='file_section'>
                    Attached Files : <a href={document.data.files.url} download={document.data.files.name}>{document.data.files.name}</a>
                </div>}
                </div>
               
                <div className="more_bt_section">
                <a href="/announcement"><button className="default_bt more_bt">Back to List</button></a>
                </div>
            </div>
        </div>
    )



}


export default AnnouncementDetail;
