import { useEffect, useState } from 'react';
import { Axios } from 'axios';
import { useAllPrismicDocumentsByType } from '@prismicio/react'
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap/all';
import {getCookie} from '../lib/common'
import $ from "jquery";
function Artist() {

    const [artistDetailYn, setArtistDetailYn] = useState(false);
    const [arPhotoYn, setArPhotoYn] = useState(true);
    const [artists] = useAllPrismicDocumentsByType('artist');
    const [artistsType,setArtistType] = useState('ALL');
    const [artistDetail,setArtistDetail] = useState({});
    const [artistActiveFixed,setArtistActiveFixed] = useState(false);
    const [filmoYn,setFilmoYn] = useState(true);
    const [othersYn,setOthersYn] = useState(true);
    const [awardsYn,setAwardsYn] = useState(true);
    const [albumYn,setAlbumYn] = useState(true);
    const [enMode,setEnMode]= useState(false); 
    const [windowWidth,setWindowWidth]= useState(window.innerWidth);
    useEffect(()=>{
        const lan = getCookie('lan');
        if(lan==='en'){
            setEnMode(true);
        }
        $(window).on('resize',()=>{
            setWindowWidth(window.innerWidth);
        })
    },[])
    useEffect(()=>{
      console.log(artists);
 
    },[artists]);

    useEffect(()=>{
       

       
      const arNames = [...document.querySelectorAll('.name_items .ar_item')];
      arNames.map((name)=>{

        let maxWidth=0.82;
        if(window.innerWidth<1200){
            maxWidth=0.75;
        }
        if(enMode){
            maxWidth=0.75;
        }
      
         if(name.getBoundingClientRect().left+name.getBoundingClientRect().width>window.innerWidth*maxWidth){
             name.classList.add('last_line');
         }else{
            name.classList.remove('last_line');
         }
      })
    },[arPhotoYn,artists,windowWidth,artistsType])
   
    const careerArMaker = (ar,type)=>{
        let newAr;
       
      
            newAr= ar.reduce((acc,cur,i)=>{
                if(i==0){
                    acc[0].push(cur);
                    return acc;
                }
                let arCheck= false;
                for(let i=0;i<acc.length;i++){

                    if(type==='filmo'){
                        if(acc[i][0].career_item.indexOf(cur.career_item.split('/')[0])>=0){
                            arCheck = true;
                            acc[i].push(cur);
                        }
                    }else{
                        if(acc[i][0].career_item2.indexOf(cur.career_item2.split('/')[0])>=0){
                            arCheck = true;
                            acc[i].push(cur);
                        }
                    }
                   
                }
                if(!arCheck) acc.push([cur]);
    
    
                return acc;
            },[[]])  

        
        return newAr;
    }

    return (
        <div className={"contents_wrap "+ (artistActiveFixed ? 'artist_fixed' : '')}>
            <div className="artist">
                <div className='ani_t_line'><div className="section_title">ARTIST</div></div>
                <div className="type_menu">
                    <button onClick={() => { setArPhotoYn(true); }}><img src={"/img/ic_photo_type" + (arPhotoYn ? '_active' : '') + ".svg"} alt="" /></button>
                    <button onClick={() => { setArPhotoYn(false) }}><img src={"/img/ic_bar_type" + (!arPhotoYn ? '_active' : '') + ".svg"} alt="" /></button>
                
                </div>
                <ul className="ar_filter">
                    <li className={artistsType==='ALL'?"active":''} onClick={() => { setArtistType('ALL'); }}>ALL</li>
                    <li className={artistsType==='ACTOR'?"active":''} onClick={() => {setArtistType('ACTOR');  }}>Actor</li>
                    <li className={artistsType==='SINGER'?"active":''} onClick={() => {setArtistType('SINGER');  }}>Singer</li>
                </ul>
                {!arPhotoYn && <div className="artist_items name_items" >

                    {artists&&artists.sort((function(a, b) { 

return  new Date(a.uid) - new Date(b.uid) 
})).filter((ar)=>{return (ar.data.type.toUpperCase()==='ALL'||artistsType==='ALL')?true:(ar.data.type.toUpperCase()==artistsType)}).map((ar,index) => {

                        return (<div key={index} className="ar_item" onClick={() => {
                            setArtistDetailYn(true);
                            setArtistDetail(ar);
                            setFilmoYn(true);
                            setOthersYn(true);
                            setAlbumYn(true);
                            setAwardsYn(true);
                            setTimeout(()=>{
                                setArtistActiveFixed(true);
                            },1000)
                        }}>
                           <div className='ar_name'>{(enMode?ar.data.name_en[0].text:ar.data.name_kr)}</div>
                            <div className='hover_img'><img src={ar.data.thumbnail_img_color.url} alt="" /></div>
                        </div>)
                    })}


                </div>}
                {artists===undefined&&<div className='loading_section'>
                        LOADING
                    </div>}
                {arPhotoYn && <div className="artist_items photo_items">
                
                {artists&&artists.sort((function(a, b) { 

return  new Date(a.uid) - new Date(b.uid)
})).filter((ar)=>{return (ar.data.type.toUpperCase()==='ALL'||artistsType==='ALL')?true:(ar.data.type.toUpperCase()==artistsType)}).map((ar,index) => {
                        return ( <div key={index}  className="ar_item" onClick={() => {
                            setArtistDetailYn(true);
                            setArtistDetail(ar);
                            setFilmoYn(true);
                            setOthersYn(true);
                            setAlbumYn(true);
                            setAwardsYn(true);
                            setTimeout(()=>{
                                setArtistActiveFixed(true);
                            },1000)
                        }}>
                            <img src={ar.data.thumbnail_img.url} alt="" />
                            <div className='ar_name'><span className='t_kr'>{ar.data.name_kr}</span><span className='t_en'>{ar.data.name_en[0].text}</span></div>
                        </div>)
                    })}
                   
                   
                   
                </div>}
                {<div className={"artist_detail " + (artistDetailYn ? 'active' : '')}>
                    {artistDetail.data!==undefined&&<div>
                    <div className="close_bt" onClick={() => { setArtistDetailYn(false);setArtistDetail({}) ;  setArtistActiveFixed(false); }}><img src="/img/ic_x_bt.svg" alt="" /></div>
                    <div className="ar_main">
                        <div className="ar_main_img"><img src={artistDetail.data.detail_img.url} alt="" /></div>
                        <div className="ar_deco_img ard1"><img src="/img/artist_deco1.png" alt="" /></div>
                        <div className="ar_deco_img ard2"><img src="/img/artist_deco2.png" alt="" /></div>
                        <div className="ar_main_info">
                            <div className="ar_name">{artistDetail.data.name_en[0].text} </div>
                            <div className="ar_subinfo">
                                <div className="ar_profile">
                                    <div className="ar_name_kr">{(enMode?artistDetail.data.name_en[0].text:artistDetail.data.name_kr)}</div>
                                    <div className="ar_birth">{artistDetail.data.type==='All'?'Actor, Singer':artistDetail.data.type}</div>
                                </div>
                                <div className="ar_sns">
                                    {artistDetail.data.sns_contact[0].facebook.url&&<div className='ar_sns_item'><a href={artistDetail.data.sns_contact[0].facebook.url} rel="noreferrer" target="_blank"><img src="/img/ic_facebook.svg" alt="" /></a></div>}
                                    {artistDetail.data.sns_contact[0].insta.url&&<div className='ar_sns_item'><a href={artistDetail.data.sns_contact[0].insta.url} rel="noreferrer" target="_blank"><img src="/img/ic_insta.svg" alt="" /></a></div>}
                                     {artistDetail.data.sns_contact[0].twitter.url&&<div className='ar_sns_item'><a href={artistDetail.data.sns_contact[0].twitter.url} rel="noreferrer" target="_blank"><img src="/img/ic_twitter.svg" alt="" /></a></div>}
                                     {artistDetail.data.sns_contact[0].youtube.url&&<div className='ar_sns_item'><a href={artistDetail.data.sns_contact[0].youtube.url} rel="noreferrer" target="_blank"><img src="/img/ic_youtube.svg" alt="" /></a></div>}
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="ar_career">
                            {artistDetail.data&&artistDetail.data.filmography[0].career_item!==null&&<div className={"arc_item "+(filmoYn?'active':'')}>
                                <div className="arc_header" onClick={()=>{setFilmoYn(!filmoYn)}}>
                                    <div className="arc_title">FILMOGRAPHY</div>
                                    <div className="arc_active_bt">{filmoYn?<img src="/img/ic_minus.svg" alt="" />:<img src="/img/ic_plus.svg" alt="" />}</div>
                                </div>
                                <div className="arc_contents">
                                {careerArMaker(artistDetail.data&&artistDetail.data.filmography,'filmo').map((cas,casIndex)=>{return (
                                    <div key={casIndex} className='career_items'>
                                       <div className='ca_title'>{cas[0].career_item.split("/")[0]}</div>
                                        {cas.map((ca,caIndex)=>(<div className="ca_item" key={caIndex}>
                                            <div className="ca_time">{ca.career_item.split("/")[1]}</div>
                                            <div className="ca_contents">{ca.career_item.split("/")[2]}</div>
                                        </div>))}
                                    </div>

                                )})
                                        }
                                   
                                </div>
                            </div>}
                            {artistDetail.data&&artistDetail.data.others[0].career_item2!==null&&<div  className={"arc_item "+(othersYn?'active':'')}>
                                <div className="arc_header" onClick={()=>{setOthersYn(!othersYn)}}>
                                    <div className="arc_title">OTHERS</div>
                                    <div className="arc_active_bt">{othersYn?<img src="/img/ic_minus.svg" alt="" />:<img src="/img/ic_plus.svg" alt="" />}</div>
                                </div>
                                <div className="arc_contents">
                                {careerArMaker(artistDetail.data.others,'others').map((cas,casIndex)=>{return (
                                    <div key={casIndex} className='career_items'>
                                       <div className='ca_title'>{cas[0].career_item2.split("/")[0]}</div>
                                        {cas.map((ca,caIndex)=>(<div className="ca_item" key={caIndex}>
                                            <div className="ca_time">{ca.career_item2.split("/")[1]}</div>
                                            <div className="ca_contents">{ca.career_item2.split("/")[2]}</div>
                                        </div>))}
                                    </div>

                                )})
                                        }
                                </div>
                            </div>}
                            {artistDetail.data.awards.length!==0&&artistDetail.data.awards[0].career_items3!==null&&<div  className={"arc_item "+(awardsYn?'active':'')}>
                                <div className="arc_header" onClick={()=>{setAwardsYn(!awardsYn)}}>
                                    <div className="arc_title">AWARD</div>
                                    <div className="arc_active_bt">{awardsYn?<img src="/img/ic_minus.svg" alt="" />:<img src="/img/ic_plus.svg" alt="" />}</div>
                                </div>
                                <div className="arc_contents">
                                <div className="career_items">
                                        {artistDetail.data&&artistDetail.data.awards.filter((aw,awIndex)=>artistDetail.data.awards.length<=10||(awIndex<artistDetail.data.awards.length/2)).map((aw,awIndex)=>{return (<div className="ca_item" key={awIndex}>
                                            <div className="ca_time">{aw.career_items3.split("/")[0]}</div>
                                            <div className="ca_contents">{aw.career_items3.split("/")[1]}</div>
                                        </div>)})
                                      }
                                    </div>
                                    <div className="career_items">
                                    {artistDetail.data&&artistDetail.data.awards.filter((aw,awIndex)=>artistDetail.data.awards.length>10&&(awIndex>=artistDetail.data.awards.length/2)).map((aw,awIndex)=>{return (<div className="ca_item" key={awIndex}>
                                            <div className="ca_time">{aw.career_items3.split("/")[0]}</div>
                                            <div className="ca_contents">{aw.career_items3.split("/")[1]}</div>
                                        </div>)})
                                      }
                                    </div>
                                </div>
                            </div>}
                            {artistDetail.data.album[0].album_item!==null&&<div  className={"arc_item "+(albumYn?'active':'')}>
                                <div className="arc_header" onClick={()=>{setAlbumYn(!albumYn)}}>
                                    <div className="arc_title">ALBUM</div>
                                    <div className="arc_active_bt">{albumYn?<img src="/img/ic_minus.svg" alt="" />:<img src="/img/ic_plus.svg" alt="" />}</div>
                                </div>
                                <div className="arc_contents">
                                    <div className="career_items">
                                        {artistDetail.data&&artistDetail.data.album.filter((al,alIndex)=>artistDetail.data.album.length<=10||(alIndex<artistDetail.data.album.length/2)).map((al,alIndex)=>{return (<div className="ca_item" key={alIndex}>
                                            <div className="ca_time">{al.album_item.split("/")[0]}</div>
                                            <div className="ca_contents">{al.album_item.split("/")[1]}</div>
                                        </div>)})
                                      }
                                    </div>
                                    <div className="career_items">
                                    {artistDetail.data&&artistDetail.data.album.filter((al,alIndex)=>artistDetail.data.album.length>10&&(alIndex>=artistDetail.data.album.length/2)).map((al,alIndex)=>{return (<div className="ca_item" key={alIndex}>
                                            <div className="ca_time">{al.album_item.split("/")[0]}</div>
                                            <div className="ca_contents">{al.album_item.split("/")[1]}</div>
                                        </div>)})
                                      }
                                    </div>
                                </div>
                            </div>}
                            
                    </div>
                    {artistDetail.data.manager&&<div className="ar_manager">
                        <div className="arm_name">담당: {artistDetail.data.manager}</div>
                        <div className="arm_contact">
                        {artistDetail.data.manager_contacts.map((arContact,arc)=>{
                            return (<div key={arc}>{arContact.contact_item}</div>)
                        })}
                    
                        </div>
                    </div>}
                    </div>}


                </div>}
            </div>
        </div>

    )



}


export default Artist;
