import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'iokwebsite'
const endpoint = prismic.getEndpoint(repositoryName)

export const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token
  accessToken: 'MC5ZZE1KMEJFQUFDWUFMZ3Z1.77-9B--_vX5u77-9YO-_vUXvv73vv73vv73vv70-BO-_vT88Z2wQ77-977-977-977-9eO-_vVbvv71NVFw',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
   
  ],
})