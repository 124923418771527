import { useEffect, useState } from 'react'; 
import { useAllPrismicDocumentsByType } from '@prismicio/react'
import {getCookie} from '../lib/common' 
import { useLocation } from 'react-router-dom';
function Dart() {
    const [dart] = useAllPrismicDocumentsByType('dart');
    
    const location = useLocation(); 
    
    useEffect(()=>{
      
    },[dart])
    return (
        <div className="contents_wrap">
            <div className="announcement">
                <div className='ani_t_line'>
                    <div className="section_title">
                       DART 공시정보
                    </div>
                    <div className="dart_container mobile_hidden">
                    <iframe src="https://dart.fss.or.kr/html/search/SearchCompanyIR3_M.html?textCrpNM=아이오케이" width="750" height="1100" title="iok dart" />
                    </div>
                    <div className="dart_container mobile_view">
                    모바일에는 공시정보를 <br/>
                    제공하지 않습니다.<br/>
                    (PC에서 확인해주세요)
                    </div>
                </div>

              
              

            </div>
        </div>

    )



}


export default Dart;
