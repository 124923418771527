import { useEffect, useState } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react'

import { useLocation } from 'react-router-dom';
function News() {
    const [news] = useAllPrismicDocumentsByType('news');
    const [newsMaxLength,setNewsMaxLength] = useState(24);
    useEffect(()=>{
        console.log(news);
},[news])

    const moreNewsClick=()=>{
        setNewsMaxLength(newsMaxLength+24);
    }

    return (
        <div className="contents_wrap">
            <div className="news">
                <div className='ani_t_line'>
                    <div className="section_title">
                        NEWS
                    </div>

                </div>
              
                <div className="news_items">
                    
                    {news&&news.sort((function(a, b) { 

return new Date(b.data.writen_date) - new Date(a.data.writen_date);
})).filter((ne,nIndex)=>nIndex<newsMaxLength).map((ne,nIndex)=>(<div key={nIndex} className="news_item">
                        <a href={ne.data.original_link.url} rel="noreferrer" target={'_blank'}>
                            <div className="news_img" style={{backgroundImage:"url("+ne.data.thumnail.url+")"}}>
                                <div className='hover_circle'>View</div>
                            </div>
                            <div className="news_title">{ne.data.title[0].text}</div>
                            <div className="news_date">{ne.data.writen_date}</div>
                        </a>
                    </div>))}

                </div>
                <div className="more_bt_section">
                    <button className="default_bt more_bt" onClick={()=>{moreNewsClick();}}>View More</button>
                </div>
            </div>
        </div>

    )



}


export default News;
